@import-normalize;

@font-face {
  font-family: 'Gotham Medium';
  src: local('Gotham Medium'),
    url(./assets/fonts/Gotham-Medium.otf) format('truetype');
}
@font-face {
  font-family: 'Gotham Bold';
  src: local('Gotham Bold'),
    url(./assets/fonts/Gotham-Bold.otf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Gotham Book';
  src: local('Gotham Book'),
    url(./assets/fonts/Gotham-Book.otf) format('truetype');
}
@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'),
    url(./assets//fonts/helveticaneue-regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Gotham Book';
}

.react-flow__node {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}
